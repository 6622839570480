<script>
import { useStore } from "@/store/store"
import comp from "../base"

export default {
  neme: "RecipeRecyme",
  props: ["recipeId"],
  components: {
    ...comp,
  },
  computed: {
    recipe() {
      return this.recipeId ? useStore().getters.popRecipe(this.recipeId) : {}
    },
    // mashingProfile() {
    //   return this.recipe.mashing.profileId ? this.getProfile(this.recipe.mashing.profileId) : null
    // },
    // fermentationProfile() {
    //   return this.recipe.fermentation.profileId
    //     ? this.getProfile(this.recipe.fermentation.profileId)
    //     : null
    // },
  },
}
</script>

<template>
  <SubHeader>
    Recipy Recyme
  </SubHeader>
  <CardBox>
    <List>
      Name :
      <template #right> {{ recipe.name }} </template>
    </List>
    <List>
      Style :
      <template #right> {{ recipe.styleName }} </template>
    </List>
    <List>
      Batch Size :
      <template #right> {{ recipe.batchSize }} L </template>
    </List>
    <List>
      Preboil Volume :
      <template #right> {{ recipe.evaporated + recipe.batchSize }} L </template>
    </List>
    <List>
      Brewery Efficiency :
      <template #right> {{ recipe.efficiency ? recipe.efficiency + " %" : " - " }} </template>
    </List>
    <List>
      Target OG :
      <template #right> {{ recipe.tog ? recipe.tog : " - " }} kg/m³ </template>
    </List>
    <List>
      Target FG :
      <template #right> {{ recipe.tfg ? recipe.tfg : " - " }} kg/m³ </template>
    </List>
    <List>
      Target ABV :
      <template #right> {{ recipe.targetAbv ? recipe.targetAbv : " - " }} % </template>
    </List>
    <List>
      Target EBC :
      <template #right> {{ recipe.targetEbc ? recipe.targetEbc : " - " }} </template>
    </List>
    <List v-if="recipe.targetSrm">
      Target SRM :
      <template #right> {{ recipe.targetSrm }} </template>
    </List>
    <List v-if="recipe.targetColor">
      Target Color :
      <template #right>
        <Badge :bg="'rgb(' + recipe.targetColor + ')'">
          Color
        </Badge>
      </template>
    </List>
    <List v-if="recipe.targetFinalPlato">
      Target Final Plato :
      <template #right> {{ recipe.targetFinalPlato }} </template>
    </List>
    <List v-if="recipe.ibuTinseth">
      Tinseth IBU :
      <template #right> {{ recipe.ibuTinseth }} </template>
    </List>
    <hr />
    <List>
      Fermenterbles :
    </List>
    <List v-for="(item, index) in recipe.mashing.items" :key="index">
      {{ item.categoryName }}
      <template #right> {{ item.name }} </template>
    </List>
    <List>
      Mashing Profile :
      <template #right> TODO </template>
    </List>
    <List>
      Mashing Time :
      <template #right> TODO minuts </template>
    </List>
    <hr />
    <List>
      Kettle :
    </List>
    <List v-if="recipe.kettle.totalTime">
      Expected Evaporation :
      <template #right> {{ recipe.evaporated }} L </template>
    </List>
    <List v-if="recipe.kettle.totalTime">
      Kettle Time :
      <template #right> {{ recipe.kettle.totalTime }} minuts </template>
    </List>
    <hr />
    <List>
      Fermentation :
    </List>
    <List>
      Attenuation :
      <template #right> {{ recipe.attenuation }} %</template>
    </List>
    <List v-for="(item, index) in recipe.fermentation.items" :key="index">
      {{ item.categoryName }}
      <template #right> {{ item.name }} </template>
    </List>
    <List>
      fermentation Time :
      <template #right> TODO days </template>
    </List>
    <hr />
    <List>
      Created :
      <template #right> {{ recipe.createdDate }} </template>
    </List>
    <List>
      Created by :
      <template #right>
        {{ recipe.createdBy }}
      </template>
    </List>
    <List>
      Updated :
      <template #right> {{ recipe.updatedDate }} </template>
    </List>
    <List>
      Updated by :
      <template #right>
        {{ recipe.editedBy }}
      </template>
    </List>
  </CardBox>
</template>
