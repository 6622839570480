<template>
  <SubHeader>
    Kettle
    <template #right><BadgeBtn @click="openLocalKettle()"> + </BadgeBtn></template>
  </SubHeader>
  <CardBox>
    <BTable :header="header">
      <tr v-for="(item, index) in popRecipe.kettle.items" :key="index">
        <KettleTableItem :item="item" :index="index" />
      </tr>
      <template #footer>
        <td
          class="fw-bold"
          v-for="(foot, index) in ['sum', , , , quantitySum + ' kg', totalTime()]"
          :key="index"
        >
          {{ foot }}
        </td>
      </template>
    </BTable>
  </CardBox>
  <!-- MODAL -->
  <KettleModal v-if="kettleModalIsOpen" :recipe="recipe" />
</template>

<script>
import { mapActions } from "vuex"

import comp from "../../components/base"
import KettleTableItem from "./KettleTableItem.vue"
import KettleModal from "./KettleModal"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default {
  name: "Kettle",
  props: ["recipe", "popRecipe"],
  components: {
    ...comp,
    KettleTableItem,
    KettleModal,
  },
  setup() {
    return {
      kettleModalIsOpen: computed(() => useStore().state.localModals.kettle),
    }
  },
  data() {
    return {
      header: ["", "catogory", "name", "aa", "quantiry", "boil time"],
      totalTime: () => this.popRecipe.kettle.totalTime + " min",
    }
  },
  computed: {
    quantitySum() {
      return this.recipe.kettle.items.reduce((acc, item) => (acc += item.quantity), 0)
    },
  },
  methods: {
    ...mapActions(["openLocalKettle"]),
  },
}
</script>
