<template>
  <SubHeader>
    RecipeInfo
  </SubHeader>
  <CardBox>
    <Row>
      <Lable>name *</Lable>
      <Lable>
        style *
        <template #right>
          <BadgeBtn @click="openStyle()"> + Style</BadgeBtn>
        </template>
      </Lable>
    </Row>
    <Row class="mb-3">
      <BCol>
        <!-- <FormInputEnableable type="number" v-model.number="ingredient.temperature" span="C°" /> -->
        <FormInputEnableable
          ref="formName"
          placeholder="Recipe Name"
          v-model="name"
          @lock="setName()"
        />
      </BCol>
      <BCol>
        <FormSelectEnableable
          ref="formStyle"
          :items="styles"
          :valueCallback="item => item.id"
          :strCallback="item => item.name"
          v-model="styleId"
          @lock="setStyle()"
        />
      </BCol>
    </Row>
    <Row>
      <Lable>Batch Volume (where ρ(H2O) = 1) *</Lable>
      <Lable>
        Brewery
        <template #right>
          <BadgeBtn @click="openBrewery()"> + Brewery</BadgeBtn>
        </template>
      </Lable>
    </Row>
    <Row class="mb-3">
      <BCol>
        <!-- <FormInputEnableable type="number" v-model.number="ingredient.temperature" span="C°" /> -->
        <FormInputEnableable
          ref="formBatch"
          placeholder="Batch Size"
          type="number"
          :validateion="validNum"
          v-model="batchSize"
          @lock="setBatch()"
          span="L"
        />
      </BCol>
      <BCol>
        <FormSelectEnableable
          ref="formBrewery"
          :items="breweries"
          :valueCallback="item => item.id"
          :strCallback="item => item.name + ' - ' + item.efficiency + ' %'"
          v-model="breweryId"
          @lock="setBrewery()"
        />
      </BCol>
    </Row>
  </CardBox>
</template>

<script>
import comp from "../../components/base"
// import { checkValidity } from "../../services/validation"
import { apiSetRecipe } from "../../services/api"
import { mapActions } from "vuex"
import { checkValidity, formError, validNum } from "../../services/validation"
import cloneDeep from "clone-deep"
import { useStore } from "@/store/store"
import { computed } from "@vue/runtime-core"
export default {
  name: "RecipeInfo",
  props: ["recipe"],
  components: {
    ...comp,
  },
  setup() {
    return {
      validNum,
      styles: computed(() => useStore().getters.styles),
      breweries: computed(() => useStore().getters.breweries),
    }
  },
  data() {
    return {
      name: null,
      styleId: null,
      breweryId: null,
      batchSize: null,
      tog: null,
      tfg: null,
    }
  },
  created() {
    this.updateData()
  },
  watch: {
    recipe() {
      this.updateData()
    },
  },
  // computed: {
  //   ...mapState(["breweries"]),
  //   styles() {
  //     return useStore().getters.styles
  //   },
  // },
  methods: {
    ...mapActions(["openStyle", "openBrewery"]),
    updateData() {
      if (this.recipe) {
        this.name = this.recipe.name ? this.recipe.name : null
        this.styleId = this.recipe.styleId ? this.recipe.styleId : null
        this.batchSize = this.recipe.batchSize ? this.recipe.batchSize : null
        this.breweryId = this.recipe.breweryId ? this.recipe.breweryId : null
      }
    },

    setBrewery() {
      if (checkValidity(this.$refs.formBrewery)) {
        let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
        recipe.breweryId = this.breweryId
        apiSetRecipe(recipe)
      } else formError()
    },

    setName() {
      if (checkValidity(this.$refs.formName)) {
        let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
        recipe.name = this.name
        apiSetRecipe(recipe)
      } else formError()
    },

    setStyle() {
      if (checkValidity(this.$refs.formStyle)) {
        let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
        recipe.styleId = this.styleId
        apiSetRecipe(recipe)
      } else formError()
    },

    setBatch() {
      if (checkValidity(this.$refs.formBatch)) {
        let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
        recipe.batchSize = this.batchSize
        apiSetRecipe(recipe)
      } else formError()
    },
  },
}
</script>
