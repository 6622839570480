<template>
  <ModalBase :close="closeLocal" :modalName="'+ Ingredient'" :openModal="openIngredient">
    <template #header>Select Hubs</template>
    <input class="form-control" placeholder="search (TODO)" />
    <BTable :header="header">
      <tr v-for="(item, index) in hubs" :key="index">
        <td><Button @click="add(item.id)"> add</Button></td>
        <td v-for="(callback, index) in dataCallbacl" :key="index">{{ callback(item) }}</td>
      </tr>
    </BTable>
  </ModalBase>
</template>

<script>
import comp from "../base"
import cloneDeep from "clone-deep"
import { mapActions } from "vuex"
import ModalBase from "../modals/ModalBase.vue"
import { CATEGORY } from "../../services/enums"
import { apiSetRecipe } from "../../services/api"
import { useStore } from "@/store/store"

export default {
  name: "MashingModal",
  props: ["recipe"],
  components: {
    ...comp,
    ModalBase,
  },
  data() {
    return {
      header: ["add", "catogory", "name", "aa"],
      dataCallbacl: [item => item.categoryName, item => item.name, item => item.aa],
    }
  },
  computed: {
    ingredients() {
      return useStore().getters.popIngredients
    },
    idsAdded() {
      return this.recipe.kettle.items.map(item => item.id)
    },
    hubs() {
      return Object.values(this.ingredients)
        .filter(item => item.category == CATEGORY.HOPS)
        .filter(item => !this.idsAdded.includes(item.id))
    },
  },
  methods: {
    ...mapActions(["openIngredient"]),
    ...mapActions(["closeLocal"]),
    add(id) {
      let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
      let index = useStore().state.localModals.kettleIndex
      if (index != null) {
        recipe.kettle.items[index].id = id
      } else {
        recipe.kettle.items.push({
          id: id,
        })
      }
      apiSetRecipe(recipe)
      this.closeLocal()
    },
  },
}
</script>
