<template>
  <Container>
    <template v-if="recipe">
      <Header>
        Recipe | {{ popRecipe.styleName }} - <strong> {{ popRecipe.name }} </strong>
      </Header>
      <PageContent>
        <Info :recipe="recipe" />
        <Mashing :recipe="recipe" />
        <Kettle :recipe="recipe" :popRecipe="popRecipe" />
        <Fermentation :recipe="recipe" />

        <template #right>
          <RecipeRecyme :recipeId="recipe.id" />
        </template>
      </PageContent>
    </template>
  </Container>
</template>

<script>
import comp from "../components/base"
import Info from "../components/recipe/Info.vue"
import RecipeRecyme from "../components/recymes/RecipeRecyme.vue"
import Mashing from "../components/recipe/Mashing.vue"
import Kettle from "../components/recipe/Kettle.vue"
import Fermentation from "../components/recipe/Fermentation.vue"
import { computed } from "vue"
import { useRoute } from "vue-router"
import { useStore } from "@/store/store"

export default {
  name: "Recipe",
  components: {
    ...comp,
    Info,
    Mashing,
    Kettle,
    Fermentation,
    RecipeRecyme,
  },
  setup() {
    let id = useRoute().params.id
    return {
      recipe: computed(() => useStore().state.collections.recipes[id]),
      popRecipe: computed(() => useStore().getters.popRecipe(id)),
    }
  },
}
</script>
