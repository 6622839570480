<template>
  <td>
    <ButtonGroup>
      <Button @click="del()"> trash</Button>
      <Button @click="openLocalKettle(index)"> edit</Button>
    </ButtonGroup>
  </td>
  <td v-for="(callback, index) in dataCallbacl" :key="index">{{ callback(item) }}</td>
  <td>
    <FormInputEnableable
      ref="formQuantity"
      type="number"
      :validateion="validNum"
      v-model.number="quantity"
      @lock="setQuantity()"
      :span="item.id ? item.unitText : null"
    />
  </td>
  <td>
    <FormInputEnableable
      ref="formTime"
      type="number"
      :validateion="validNum"
      v-model.number="time"
      @lock="setTime()"
      span="min"
    />
  </td>
</template>

<script>
import comp from "../base"
import { mapActions } from "vuex"
import cloneDeep from "clone-deep"
import { apiSetRecipe } from "../../services/api"
import { checkValidity, formError, validNum } from "../../services/validation"
import { useStore } from "@/store/store"

export default {
  name: "KettleTableItem",
  props: ["item", "index"],
  components: {
    ...comp,
  },
  setup() {
    return {
      validNum,
    }
  },
  data() {
    return {
      dataCallbacl: [item => item.categoryName, item => item.name, item => item.aa + "%"],
      recipeId: this.$route.params.id,
      quantity: null,
      time: null,
    }
  },
  created() {
    this.setData()
  },
  watch: {
    item() {
      this.setData()
    },
  },
  // computed: {
  //   recipe() {
  //     return useStore().state.collections.recipes[this.recipeId]
  //   },
  // },
  methods: {
    ...mapActions(["openLocalKettle"]),

    setData() {
      if (this.item) {
        this.quantity = this.item.quantity
        this.time = this.item.time
      }
    },

    del() {
      let local = cloneDeep(useStore().state.collections.recipes[this.recipeId])
      local.kettle.items.splice(this.index, 1)
      apiSetRecipe(local)
    },

    setQuantity() {
      let local = cloneDeep(useStore().state.collections.recipes[this.recipeId])
      local.kettle.items[this.index].quantity = this.quantity

      if (checkValidity(this.$refs.formQuantity)) apiSetRecipe(local)
      else formError()
    },

    setTime() {
      let local = cloneDeep(useStore().state.collections.recipes[this.recipeId])
      local.kettle.items[this.index].time = this.time

      if (checkValidity(this.$refs.formTime)) apiSetRecipe(local)
      else formError()
    },
  },
}
</script>
