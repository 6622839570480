<template>
  <SubHeader>
    Mashing
    <template #right><BadgeBtn @click="openLocalFermentable()"> + </BadgeBtn></template>
  </SubHeader>
  <CardBox>
    <BTable :header="['', 'catogory', 'name', 'ebc', 'ppg', 'quantity']">
      <tr v-for="(item, index) in popRecipe.mashing.items" :key="index">
        <MashingTableItem :item="item" :index="index" />
      </tr>
      <template #footer>
        <td
          class="fw-bold"
          v-for="(foot, index) in ['sum', , , , , quantitySum + ' kg']"
          :key="index"
        >
          {{ foot }}
        </td>
      </template>
    </BTable>

    <ProfileGraph
      v-if="profile"
      :chartId="'mashing'"
      :items="profile.items"
      :totalTime="profile.totalTime"
    />
    <Lable
      >Mashing Profile *
      <template #right>
        <BadgeBtn @click="openProfile()"> + Profile</BadgeBtn>
      </template>
    </Lable>
    <Row>
      <BCol>
        <FormSelectEnableable
          ref="formProfiel"
          :items="profiles.filter(item => item.type == PROFILE.MASHING)"
          :valueCallback="item => item.id"
          :strCallback="item => item.name + ' | ' + item.totalTime + ' minuts'"
          v-model="profileId"
          @lock="setProfile()"
        />
      </BCol>
      <Button v-if="profileId" class="col-2 mx-1" @click="openProfile(profileId)"> edit</Button>
    </Row>
  </CardBox>
  <!-- MODAL -->
  <MashingModal v-if="fermentableModalIsOpen" :recipe="recipe" />
</template>

<script>
import comp from "../../components/base"
import { mapActions, mapGetters } from "vuex"
import cloneDeep from "clone-deep"
import { apiSetRecipe } from "../../services/api"
import { PROFILE } from "../../services/enums"
import ProfileGraph from "./ProfileGraph.vue"
import MashingTableItem from "./MashingTableItem.vue"
import MashingModal from "./MashingModal.vue"
import { checkValidity, formError } from "../../services/validation"
import { useStore } from "@/store/store"
import { computed } from "@vue/runtime-core"

export default {
  name: "Mashing",
  props: ["recipe"],
  components: {
    ...comp,
    ProfileGraph,
    MashingTableItem,
    MashingModal,
  },
  setup() {
    return {
      PROFILE,
      fermentableModalIsOpen: computed(() => useStore().state.localModals.fermentable),
    }
  },
  data() {
    return {
      profileId: null,
    }
  },
  created() {
    this.updateData()
  },
  watch: {
    recipe() {
      this.updateData()
    },
  },
  computed: {
    ...mapGetters(["profiles"]),

    profile() {
      return this.recipe.mashing.profileId
        ? useStore().getters.profile(this.recipe.mashing.profileId)
        : null
    },
    popRecipe() {
      return useStore().getters.popRecipe(this.recipe.id)
    },
    quantitySum() {
      return this.recipe.mashing.items.reduce((acc, item) => (acc += item.quantity), 0)
    },
  },
  methods: {
    ...mapActions(["openProfile"]),
    ...mapActions(["openLocalFermentable"]),
    updateData() {
      if (this.recipe) {
        this.profileId = this.recipe.mashing.profileId
      }
    },
    setProfile() {
      if (checkValidity(this.$refs.formProfiel)) {
        let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
        recipe.mashing.profileId = this.profileId
        apiSetRecipe(recipe)
      } else formError()
    },
  },
}
</script>

<style scoped>
.localTable {
  vertical-align: middle;
}
</style>
