<template>
  <ModalBase :close="closeLocal" :modalName="'+ Type'" :openModal="openIngredient">
    <template #header>Select Fermenterble</template>
    <input class="form-control" placeholder="search (TODO)" />
    <table class="table table-hover ">
      <thead>
        <tr>
          <th v-for="(item, index) in ['add', 'catogory', 'name', 'ebc', 'ppg']" :key="index">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in fermentables" :key="index">
          <td><Button @click="add(item.id)"> add</Button></td>
          <td
            v-for="(callback, index) in [
              item => item.categoryName,
              item => item.name,
              item => item.ebc,
              item => item.ppg,
            ]"
            :key="index"
          >
            {{ callback(item) }}
          </td>
        </tr>
      </tbody>
    </table>
  </ModalBase>
</template>

<script>
import comp from "../base"
import cloneDeep from "clone-deep"
import { mapActions } from "vuex"
import ModalBase from "../modals/ModalBase.vue"
import { CATEGORY } from "../../services/enums"
import { apiSetRecipe } from "../../services/api"
import { useStore } from "@/store/store"

export default {
  name: "MashingModal",
  props: ["recipe"],
  components: {
    ...comp,
    ModalBase,
  },
  data() {
    return {}
  },
  computed: {
    ingredients() {
      return useStore().getters.popIngredients
    },
    idsAdded() {
      return this.recipe.mashing.items.map(item => item.id)
    },
    fermentables() {
      return Object.values(this.ingredients)
        .filter(item => (item.category == CATEGORY.MALT) | (item.category == CATEGORY.SUGAR))
        .filter(item => !this.idsAdded.includes(item.id))
    },
  },
  methods: {
    ...mapActions(["openIngredient"]),
    ...mapActions(["closeLocal"]),
    add(id) {
      let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
      let index = useStore().state.localModals.fermentableIndex
      if (index != null) {
        recipe.mashing.items[index].id = id
      } else {
        recipe.mashing.items.push({
          id: id,
          quantity: null,
        })
      }
      apiSetRecipe(recipe)
      this.closeLocal()
    },
  },
}
</script>
