<template>
  <ModalBase :close="closeLocal" :modalName="'+ Ingredient'" :openModal="openIngredient">
    <template #header>Select Yeast</template>
    <input class="form-control" placeholder="search (TODO)" />
    <BTable :header="['add', 'catogory', 'name', 'attenuation']">
      <tr v-for="(item, index) in yeast" :key="index">
        <td><Button @click="add(item.id)"> add</Button></td>
        <td
          v-for="(callback, index) in [
            item => item.categoryName,
            item => item.name,
            item => item.attenuation + ' %',
          ]"
          :key="index"
        >
          {{ callback(item) }}
        </td>
      </tr>
    </BTable>
  </ModalBase>
</template>

<script>
import comp from "../base"
import cloneDeep from "clone-deep"
import { mapActions } from "vuex"
import ModalBase from "../modals/ModalBase.vue"
import { CATEGORY } from "../../services/enums"
import { apiSetRecipe } from "../../services/api"
import { useStore } from "@/store/store"

export default {
  name: "MashingModal",
  props: ["recipe"],
  components: {
    ...comp,
    ModalBase,
  },
  data() {
    return {}
  },
  computed: {
    ingredients() {
      return useStore().getters.popIngredients
    },
    idsAdded() {
      return this.recipe.fermentation.items.map(item => item.id)
    },
    yeast() {
      return Object.values(this.ingredients)
        .filter(item => item.category == CATEGORY.YEAST)
        .filter(item => !this.idsAdded.includes(item.id))
    },
  },
  methods: {
    ...mapActions(["openIngredient"]),
    ...mapActions(["closeLocal"]),
    add(id) {
      let recipe = cloneDeep(useStore().state.collections.recipes[this.recipe.id])
      let index = useStore().state.localModals.fermentableIndex
      if (index != null) {
        recipe.fermentation.items[index].id = id
      } else {
        recipe.fermentation.items.push({
          id: id,
        })
      }
      apiSetRecipe(recipe)
      this.closeLocal()
    },
  },
}
</script>
